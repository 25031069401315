// Click Function
jQuery(document).ready(function($) {
  $('#scrollTop').click(function(e) {
    let target = $('#swup')
    e.preventDefault()
    // Animate Page
    $('html, body').animate({
      scrollTop: target.offset().top
    }, 800)
  })
  
  // Scroll to show button
  $(window).scroll(function() {
    if($(window).scrollTop() >= 100) {
      $('#scrollTop').addClass('active')
    }
    else {
      $('#scrollTop').removeClass('active')
    }
  })
  
  $(document).ready(function() {
    if($(window).scrollTop() >= 100) {
      $('#scrollTop').addClass('active')
    }
  })
  
  // Correct for new page events
  document.addEventListener('swup:contentReplaced', function() {
    // Click Function
      $('#scrollTop').click(function(e) {
        let target = $('#swup')
        e.preventDefault()
        // Animate Page
        $('html, body').animate({
          scrollTop: target.offset().top
        }, 800)
      })
    
    // Scroll to show button
    $(window).scroll(function() {
      if($(window).scrollTop() >= 100) {
        $('#scrollTop').addClass('active')
      }
      else {
        $('#scrollTop').removeClass('active')
      }
    })
    
    $(document).ready(function() {
      if($(window).scrollTop() >= 100) {
        $('#scrollTop').addClass('active')
      }
    })
  })
})