import { setTimeout } from "timers"

// Animate Tree
jQuery(document).ready(function($) {
  // let triggerPoint = $(window).scrollTop() + 3 * ($(window).height() / 4)

  let tree = $('.tree')
  let branches = $('.tree-branch') // this is a group
  let trunk = $('.tree-body') // this is the center line
  let leaf = $('.tree-icon')
  let delay = 200
  
  window.addEventListener('scroll', function() {
    
    // Check if wrap has class 'animate'
    if(tree.hasClass('animate')) {
      let treePosition = tree.offset().top
      let treeBottom = treePosition + tree.height()
      let triggerPoint = $(window).scrollTop() + 3 * ($(window).height() / 4)
      
      // Animate line
      if(triggerPoint >= treePosition && $(window).scrollTop() <= treeBottom) {
        setTimeout(function() {
          trunk.addClass('grow')
        }, delay)
      }

      // Animate Branches
      branches.each(function() {
        let branch = $(this)
        let branchPosition = branch.offset().top
        let branchIndex = branches.index(this) + 1

        if(triggerPoint >= branchPosition && $(window).scrollTop() <= treeBottom) {
          if(branch.hasClass('show')) {
            delay = 100
          } else {
            setTimeout(function() {
              branch.addClass('show')
            }, delay*branchIndex)
          }
        }
      })

    }

  })
  
  // Correct for page transition
  document.addEventListener('swup:contentReplaced', function() {
    // let triggerPoint = $(window).scrollTop() + 3 * ($(window).height() / 4)
    
    let tree = $('.tree')
    let branches = $('.tree-branch') // this is a group
    let trunk = $('.tree-body') // this is the center line
    let leaf = $('.tree-icon')
    let delay = 200
    
    window.addEventListener('scroll', function() {
      
      // Check if wrap has class 'animate'
      if(tree.hasClass('animate')) {
        let treePosition = tree.offset().top
        let treeBottom = treePosition + tree.height()
        let triggerPoint = $(window).scrollTop() + 3 * ($(window).height() / 4)
        
        // Animate line
        if(triggerPoint >= treePosition && $(window).scrollTop() <= treeBottom) {
          setTimeout(function() {
            trunk.addClass('grow')
          }, delay)
        }
        
        // Animate Branches
        branches.each(function() {
          let branch = $(this)
          let branchPosition = branch.offset().top
          let branchIndex = branches.index(this) + 1
          
          if(triggerPoint >= branchPosition && $(window).scrollTop() <= treeBottom) {
            if(branch.hasClass('show')) {
              delay = 100
            } else {
              setTimeout(function() {
                branch.addClass('show')
              }, delay*branchIndex)
            }
          }
        })
        
      }
      
    })
  })
})