// Possible Library
import Swup from 'swup'
import swupMergeHeadPlugin from 'swup/plugins/swupMergeHeadPlugin'

let options = {
  // cache: false,
  // preload: false,
  debugMode: false,
  LINK_SELECTOR: 'a[href^="' + window.location.origin + '"]:not([data-no-swup]):not([target="_blank"]), a[href^="/"]:not([data-no-swup]), a[href^="#"]:not([data-no-swup])',
  scroll: false,
  // debugMode: true,
  skipPopStateHandling: function(event){
    if (event.state && event.state.source == "swup") {
      return false;
    } else {
      window.location.reload()
      return true;
    }
  },
  plugins: [
    swupMergeHeadPlugin
  ]
}

const swup = new Swup(options)

// Page Lifecycle

// Entering Page
// document.addEventListener('DOMContentLoaded', function() {
//   console.log('HTML is loaded!')
//   document.body.classList.add('is-animating')
// })

// Animate initial page load
window.onload = function() {
  document.documentElement.classList.remove('is-animating')
}

// // Leaving Page
// window.onbeforeunload = function() {
//   document.body.classList.add('is-animating')
// }

// Form honeypot fix
// swup.on('clickLink', function(e) {
//   let formElement = e.view.document.all.formHash
//   console.log(formElement)
// })

// Google Analytics Fix
document.addEventListener('swup:contentReplaced', function() {
    window.ga('set', 'title', document.title)
    window.ga('set', 'page', window.location.pathname + window.location.search)
    window.ga('send', 'pageview')

    // Load Inline Scripts
    let main = document.getElementById('swup')
    let scripts = main.getElementsByTagName('script')
    for (var n = 0; n < scripts.length; n++) {
      eval(scripts[n].innerHTML)
    }
});

window.ga('set', 'title', document.title)
window.ga('set', 'page', window.location.pathname + window.location.search)
window.ga('send', 'pageview')

// Fix routing
document.addEventListener('swup:animationInDone', function() {
  
  var scrollV, scrollH, loc = window.location
  var hashTarget = loc.hash
  
  // console.log(hashTarget)

  if(hashTarget.length > 0 && $(hashTarget).length) {
    // Scroll to section at pageload
    $('html, body').animate({
      scrollTop: $(hashTarget).offset().top - $('header').height()
    }, 1000 )
    
  } else {
    // Scroll to top on page load
    $('html, body').animate({
      scrollTop: $('html, body').offset().top
    }, 600 )
  }
  
  
  if ('pushState' in history) {
    // history.pushState('', document.title, loc.pathname + loc.search)
    swup.scrollToElement = null
  } else {
    // Prevent scrolling by storing the page's current scroll offset
    scrollV = document.body.scrollTop
    scrollH = document.body.scrollLeft
    
    loc.hash = ''
    swup.scrollToElement = null

    // Restore the scroll offset, should be flicker free
    document.body.scrollTop = scrollV
    document.body.scrollLeft = scrollH
  }
  
  // history.pushState('', document.title, window.location.pathname + window.location.search)
  

})