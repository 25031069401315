jQuery(document).ready(function($) {
  // Bootstrap Tooltips
  $('[data-toggle="tooltip"]').tooltip()

  // toggle the background gradient direction
  var flipped = false

  $('.has-gradient-bg').each( function(e) {

    if(e == 0 && $(this).hasClass('invert')) {
      flipped = true
    }
    
    if( e%2 == 0 ) {
      if(flipped == true) {
        $(this).addClass('invert')
      }
    } else {
      if(flipped == false) {
        $(this).addClass('invert')
      }
    }
  })
  
  // Fix back button
  // window.addEventListener('popstate', function(event) {
  //   window.location = document.referrer 
  // }, false)
  
  // Correct event listeners
  document.addEventListener('swup:contentReplaced', function() {
    
    
    $('[data-toggle="tooltip"]').tooltip()
    
    // window.addEventListener('popstate', function(event) {
      //   window.location = document.referrer 
      // }, false)
      
      // toggle the background gradient direction
      var flipped = false
      
      $('.has-gradient-bg').each( function(e) {
        
        if(e == 0 && $(this).hasClass('invert')) {
          flipped = true
        }
        
        if( e%2 == 0 ) {
          if(flipped == true) {
            $(this).addClass('invert')
          }
        } else {
          if(flipped == false) {
            $(this).addClass('invert')
          }
        }
      })
      
    })
    
  })