jQuery(document).ready(function($){

  $('.video-slider').slick({
    // infinite: true,
    slidesToShow: 1,
    centerMode: true,
    variableWidth: true,
    lazyLoad: 'ondemand',
    prevArrow: '<button aria-label="Previous" name="prevArrow" type="button" class="btn-slider"><i class="fal fa-chevron-left"></i></button>',
    nextArrow: '<button aria-label="Next" name="nextArrow" type="button" class="btn-slider"><i class="fal fa-chevron-right"></i></button>'
  })
  
  document.addEventListener('swup:contentReplaced', function() {
    
    $('.video-slider').slick({
      infinite: true,
      slidesToShow: 1,
      centerMode: true,
      variableWidth: true,
      lazyLoad: 'ondemand',
      prevArrow: '<button aria-label="Previous" name="prevArrow" type="button" class="btn-slider"><i class="fal fa-chevron-left"></i></button>',
      nextArrow: '<button aria-label="Next" name="nextArrow" type="button" class="btn-slider"><i class="fal fa-chevron-right"></i></button>'
    })
    
  })
})