let tabSlider = $('.tab-body')
let tab = $('.tab')
let tabs = $('.tabs-wrapper')
let w = window.innerWidth
let menuHeight = $('header').height()

// Load when document is ready
jQuery(document).ready(function($){

  tabSlider.slick({
    arrows: false,
    adaptiveHeight: true,
    fade: true
  })
  
  // Change slide based on tab being clicked
  tab.click(function(){
    var tabNumber = $(this).data('tab') - 1
    tab.removeClass('active')
    $(this).addClass('active')
    // $(this).parents('.tab-slider').children('.tab-body').slick('slickGoTo', tabNumber)
    tabSlider.slick('slickGoTo', tabNumber)
  })
  
  
  tabSlider.on('init', function(slick) {
    let currentSlide = 0
    let slideNumber = currentSlide + 1
    let currentTab = $('.tab[data-tab=' + slideNumber + ']')
    
    currentTab.addClass('active')
  })
  
  // Change Tab Class on tabSlider's 
  tabSlider.on('swipe', function(event, slick, direction) {
    let slideNumber = slick.currentSlide + 1
    let currentTab = $('.tab[data-tab=' + slideNumber + ']')
    
    tab.removeClass('active')
    currentTab.addClass('active')
  })
  
  
  // Load when page is loaded
  document.addEventListener('swup:contentReplaced', function() {
      
      let tabSlider = $('.tab-body')
      let tab = $('.tab')
      let tabs = $('.tabs-wrapper')
      let w = window.innerWidth
      let menuHeight = $('header').height()
      
      tabSlider.slick({
        arrows: false,
        adaptiveHeight: true,
        fade: true
      })
      
      // Set current tab as active
      let currentSlide = 0
      let slideNumber = currentSlide + 1
      let currentTab = $('.tab[data-tab=' + slideNumber + ']')
      
      currentTab.addClass('active')
      
      tab.click(function(){
        let tabNumber = $(this).data('tab') - 1
        // let thisSlider = $(this).parents('.tab-slider').children('.tab-body')
        
        tab.removeClass('active')
        $(this).addClass('active')
        tabSlider.slick('slickGoTo', tabNumber)
      })
      
      // Change Tab Class on tabSlider's 
      tabSlider.on('swipe', function(event, slick, direction) {
        let slideNumber = slick.currentSlide + 1
        let currentTab = $('.tab[data-tab=' + slideNumber + ']')
        
        tab.removeClass('active')
        currentTab.addClass('active')
      })
      
  })
})