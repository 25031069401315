jQuery(document).ready(function($) {

  $('a[href*="#"]')
  .not('[href="#"]')
  .not('[href="#0"]')
  .click(function(e) {
    // On-page links
    if(
      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
      &&
      location.hostname == this.hostname
    ) {
      // Figure out element to scroll to
      let target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      // Correct for sticky header height
      let sHeadHeight = $('header').height()

      // Does a scroll target exist?
      if(target.length) {
        // Only prevent default if animation is going to happen
        e.preventDefault()
        // location.hash = ''
        
        // Animate page
        $('html, body').animate({
          scrollTop: target.offset().top - sHeadHeight
        }, 1000 )
      }
    }

  })

  
  // Correct for new page events
  document.addEventListener('swup:contentReplaced', function() {
    
  // let hashTarget = window.location.hash
  
  // if(hashTarget.length > 0 && $(hashTarget).length) {
    //   // Scroll to section at pageload
    //   $('html, body').animate({
      //     scrollTop: $(hashTarget).offset().top - $('header').height()
      //   }, 1000 )
      // } else {
        //   // Scroll to top on page load
        //   $('html, body').animate({
          //     scrollTop: $('html, body').offset().top
          //   }, 600 )
          // }
          
          $('a[href*="#"]')
          .not('[href="#"]')
          .not('[href="#0"]')
          .click(function(e) {
            // On-page links
            if(
              location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
              &&
              location.hostname == this.hostname
              ) {
                // Figure out element to scroll to
                let target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                // Correct for sticky header height
                let sHeadHeight = $('header').height()
                
                // Does a scroll target exist?
                if(target.length) {
                  // Only prevent default if animation is going to happen
                  e.preventDefault()
                  
                  // Animate page
                  $('html, body').animate({
                    scrollTop: target.offset().top - sHeadHeight
                  }, 1000 )
                }
              }
            })
            
          })
          
        })