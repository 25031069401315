jQuery(document).ready(function($) {

  $(window).scroll(function() {
    if($(window).scrollTop() >= 16) {
      $('header').addClass('stuck')
    }
    else {
      $('header').removeClass('stuck')
    }
    
    if($(window).scrollTop() >= 100) {
      $('header').addClass('squished')
    }
    else {
      $('header').removeClass('squished')
    }
  })
  
  // Set header position on page load
  $(document).ready(function() {
    if($(window).scrollTop() >= 16) {
      $('header').addClass('stuck')
    }
    if($(window).scrollTop() >= 100) {
      $('header').addClass('squished')
    }
  })
  
  // Correct for new page events
  document.addEventListener('swup:contentReplaced', function() {
    
    if($(window).scrollTop() >= 16) {
      $('header').addClass('stuck')
    }
    if($(window).scrollTop() >= 100) {
      $('header').addClass('squished')
    }
  })
})