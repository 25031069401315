// Load when document is ready
jQuery(document).ready( function($) {

  let changeHeight = false

  if ( window.innerWidth >= 992 ) {
    changeHeight = false
  } else {
    changeHeight = true
  }

  $('.hero-slider').slick({
    prevArrow: '<button aria-label="Slide Left" name="slideLeft" type="button" class="btn-slider"><i class="fal fa-chevron-left"></i></button>',
    nextArrow: '<button aria-label="Slide Right" name="slideRight" type="button" class="btn-slider"><i class="fal fa-chevron-right"></i></button>',
    adaptiveHeight: changeHeight,
    autoplay: true,
    autoplaySpeed: 10000
  })
  
  // Load when page is loaded
  document.addEventListener('swup:contentReplaced', function() {
    
    let changeHeight = false
    
    if ( window.innerWidth >= 992 ) {
      changeHeight = false
    } else {
      changeHeight = true
    }
    
    $('.hero-slider').slick({
      prevArrow: '<button aria-label="Slide Left" name="slideLeft" type="button" class="btn-slider"><i class="fal fa-chevron-left"></i></button>',
      nextArrow: '<button aria-label="Slide Right" name="slideRight" type="button" class="btn-slider"><i class="fal fa-chevron-right"></i></button>',
      adaptiveHeight: changeHeight,
      autoplay: true,
      autoplaySpeed: 10000
    })
    
  })
})