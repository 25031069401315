jQuery(document).ready(function($) {
  $('.state-button').click( function() {
    let state = $(this).data('state')
    let reps = $('.rep')
    let temp = $('.temp')
    let targets = $( '.rep.' + state)

    $('.state-button').removeClass('active')
    $(this).addClass('active')

    temp.addClass('hide')
    reps.removeClass('show')

    targets.each(function() {
      $(this).addClass('show')
    })
  })

  $('#stateSelect').change( function() {
    let state = $(this).val()
    let reps = $('.rep')
    let temp = $('.temp')
    let targets = $( '.rep.' + state)

    temp.addClass('hide')
    reps.removeClass('show')

    targets.each(function() {
      $(this).addClass('show')
    })
  })
  
  // Correct for new page events
  document.addEventListener('swup:contentReplaced', function() {
    
    $('.state-button').click( function() {
      let state = $(this).data('state')
      let reps = $('.rep')
      let temp = $('.temp')
      let targets = $( '.rep.' + state)
      
      $('.state-button').removeClass('active')
      $(this).addClass('active')
      
      temp.addClass('hide')
      reps.removeClass('show')
      
      targets.each(function() {
        $(this).addClass('show')
      })
    })
    
    $('#stateSelect').change( function() {
      let state = $(this).val()
      let reps = $('.rep')
      let temp = $('.temp')
      let targets = $( '.rep.' + state)
      
      temp.addClass('hide')
      reps.removeClass('show')
      
      targets.each(function() {
        $(this).addClass('show')
      })
    })
    
  })
})