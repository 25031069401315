import anime from 'animejs'

jQuery(document).ready(function($) {

  // Show and Hide Mobile Menu
  $('#burger').click(function() {
    let mMenu = $('#mobile-menu')
    let mMenuId = mMenu.attr('id')
    let hHeight = $('header').height()
    let wHeight = $(window).height()
    
    // Check if menu is showing
    if(mMenu.hasClass('show')) {
      // Remove Class 'show'
      mMenu.removeClass('show')

      // Animate Menu Closed
      let hideMenu = anime({
        targets: '#' + mMenuId,
        height: 0,
        duration: 500,
        easing: 'easeInOutQuart'
      })
    } else {
      // Add Class 'show'
      mMenu.addClass('show')

      // Animate Menu Open
      let showMenu = anime({
        targets: '#' + mMenuId,
        height: wHeight - hHeight,
        duration: 500,
        easing: 'easeInOutQuart'
      })
    }
  })

  // Navigate within mobile menu
  $('.mobile-nav-button').click(function(){
    let targetId = $(this).data('panel')
    let target = '#' + targetId
    let thisPanel = $(this).parents('.panel').attr('id')
    thisPanel = '#' + thisPanel

    if(targetId == 'root') {
      let hideSub = anime({
        targets: thisPanel,
        translateX: '0',
        duration: 500,
        easing: 'easeInOutQuart'
      })

      let showRoot = anime({
        targets: target,
        translateX: '0',
        duration: 500,
        easing: 'easeInOutQuart'
      })
    } else {
      let hideRoot = anime({
        targets: thisPanel,
        translateX: '-100%',
        duration: 500,
        easing: 'easeInOutQuart'
      })

      let showSub = anime({
        targets: target,
        translateX: '-100%',
        duration: 500,
        easing: 'easeInOutQuart'
      })
    }
  })

  
  
  // Correct for new page events
  document.addEventListener('swup:contentReplaced', function() {
    
    // Show and Hide Mobile Menu
    $('#burger').click(function() {
      let mMenu = $('#mobile-menu')
      let mMenuId = mMenu.attr('id')
      let hHeight = $('header').height()
      let wHeight = $(window).height()
      
      // Check if menu is showing
      if(mMenu.hasClass('show')) {
        // Remove Class 'show'
        mMenu.removeClass('show')
        
        // Animate Menu Closed
        let hideMenu = anime({
          targets: '#' + mMenuId,
          height: 0,
          duration: 500,
          easing: 'easeInOutQuart'
        })
      } else {
        // Add Class 'show'
        mMenu.addClass('show')
        
        // Animate Menu Open
        let showMenu = anime({
          targets: '#' + mMenuId,
          height: wHeight - hHeight,
          duration: 500,
          easing: 'easeInOutQuart'
        })
      }
    })
    
    // Navigate within mobile menu
    $('.mobile-nav-button').click(function(){
      let targetId = $(this).data('panel')
      let target = '#' + targetId
      let thisPanel = $(this).parents('.panel').attr('id')
      thisPanel = '#' + thisPanel
      
      if(targetId == 'root') {
        let hideSub = anime({
          targets: thisPanel,
          translateX: '0',
          duration: 500,
          easing: 'easeInOutQuart'
        })
        
        let showRoot = anime({
          targets: target,
          translateX: '0',
          duration: 500,
          easing: 'easeInOutQuart'
        })
      } else {
        let hideRoot = anime({
          targets: thisPanel,
          translateX: '-100%',
          duration: 500,
          easing: 'easeInOutQuart'
        })
        
        let showSub = anime({
          targets: target,
          translateX: '-100%',
          duration: 500,
          easing: 'easeInOutQuart'
        })
      }
    })
    
  })
})