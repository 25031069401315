import anime from 'animejs'

jQuery(document).ready(function($){
  // Show and hide search bar
  $('#search').click(function() {
    let sBar = $('#searchBar')
    let sBarId = sBar.attr('id')
    let sBarHeight = '5rem'

    // Check if search bar is showing
    if(sBar.hasClass('show')) {
      // Remove class 'show'
      sBar.removeClass('show')

      // Animate Menu Closed
      let hideBar = anime({
        targets: '#' + sBarId,
        height: 0,
        duration: 300,
        easing: 'easeInOutQuart'
      })
    } else {
      // Add class 'show'
      sBar.addClass('show')

      let showMenu = anime({
        targets: '#' + sBarId,
        height: sBarHeight,
        duration: 300,
        easing: 'easeInOutQuart'
      })
    }
  })
  
  document.addEventListener('swup:contentReplaced', function() {
    // Show and hide search bar
    $('#search').click(function() {
      let sBar = $('#searchBar')
      let sBarId = sBar.attr('id')
      let sBarHeight = '5rem'
      
      // Check if search bar is showing
      if(sBar.hasClass('show')) {
        // Remove class 'show'
        sBar.removeClass('show')
        
        // Animate Menu Closed
        let hideBar = anime({
          targets: '#' + sBarId,
          height: 0,
          duration: 300,
          easing: 'easeInOutQuart'
        })
      } else {
        // Add class 'show'
        sBar.addClass('show')
        
        let showMenu = anime({
          targets: '#' + sBarId,
          height: sBarHeight,
          duration: 300,
          easing: 'easeInOutQuart'
        })
      }
    })
  })
})