require('./jquery.csv.js')

jQuery(document).ready(function($) {
// Check url path (must equal '/resources/product-finder')
var pathname = window.location.pathname
if (pathname === '/resources/product-finder') {
  // console.log(pathname)
  
  var internalSkus = []
  var externalSkus = []
  
  // Define the fields we may try to match a search to
  var fuzzy = {
    'localSku': [],
    'sku': [],
    'brand': [],
    'company': []
  }
  
  // Define output table columns
  var fields = {
    'sku': {
      'label': 'SKU',
      'format': 'text'
    },
    'company': {
      'label': 'Company',
      'format': 'text'
    },
    'brand': {
      'label': 'Brand',
      'format': 'text'
    },
    'description': {
      'label': 'Description',
      'format': 'text'
    },
    'sheetSize': {
      'label': 'Sheet Size',
      'format': 'text'
    },
    'count': {
      'label': 'Count',
      'format': 'int'
    },
    'itemsCase': {
      'label': 'Items / Case',
      'format': 'int'
    },
    'qtyCase': {
      'label': 'Qty / Case',
      'format': 'int'
    }
  }
  
    
    // Get External Skus
    var internalSkusDb = document.getElementById('productSkuSearch').dataset.internal
    var csv = document.getElementById('productSkuSearch').dataset.external
    
    $.ajax({
      type: 'get',
      url: csv,
      dataType: 'text',
      success: function (data) {
        $.each($.csv.toArrays(data), function() {
          if (isInt(this[9])) {
            externalSkus[String(this[1]).toLowerCase()] = {
              'sku': String(this[1]),
              'productCategory': this[2],
              'productType': this[3],
              'company': this[4],
              'brand': this[5],
              'description': this[6],
              'sheetSize': this[7],
              'count': toInt(this[8]),
              'itemsCase': toInt(this[9]),
              'qtyCase': toInt(this[10]),
              'internalSkus': this[11].split(',')
            }
            
            fuzzy['localSku'] = fuzzy['localSku'].concat(this[11].split(','))
            fuzzy['sku'].push(String(this[1]).toLowerCase())
            fuzzy['brand'].push(this[5])
            fuzzy['company'].push(this[4])
          }
        })
        
        $.each(fuzzy, function(key) {
          fuzzy[key] = FuzzySet(arrayUnique(this))
        })
        
        $('#productSkuBtn').click(function (e) {
          e.preventDefault()
          runSearch()
        })
        
        $('#productSku').keypress(function (e) {
          if (e.which == 13) {
            runSearch()
          }
        })
      }
    })
    
    $.getJSON( internalSkusDb, function(data) {
      // Cleanup internal SKUs
      // internalSkus = []
      
      $.each(data, function() {
        internalSkus[String(this.SKU).toLowerCase()] ={
          'sku': String(this.SKU),
          'company': this.COMPANY,
          'brand': String(this.DESCRIPTION).substring(0, String(this.DESCRIPTION).indexOf(' ')),
          'description': this.DESCRIPTION,
          'sheetSize': this.SHEETSIZE,
          'count': toInt(this.COUNT),
          'itemsCase': toInt(this.ITEMSCASE),
          'qtyCase': toInt(this.COUNT) * toInt(this.ITEMSCASE),
          'url': this.URL
        }
      })
    })
    
    
    
    function runSearch() {
      $('.form-output').html('<div>Searching . . . </div>')
      
      var searchVal = $('#productSku').val().toLowerCase()
      
      var fuzzyResult = null
      var match = {
        'key': null,
        'val': null,
        'score': 0
      }
      
      $.each(fuzzy, function(key) {
        fuzzyResult = this.get(searchVal)
        if ((fuzzyResult != null) && (fuzzyResult[0][0] > match.score)) {
          match = {
            'key': key,
            'val': fuzzyResult[0][1],
            'score': fuzzyResult[0][0]
          }
        }
      })
      
      // console.log(internalSkus)
      
      // Find the matching rows from the raw data
      var rows = []
      
      switch (match.key) {
        case 'localSku':
        // The user searched for a Solaris SKU, just add that result and we're done
        rows.push(internalSkus[match.val])
        break
        
        default:
        for (var key in externalSkus) {
          var externalProduct = externalSkus[key]
          if (externalProduct != null) {
            if (externalProduct[match.key].toLowerCase() == match.val.toLowerCase()) {
              rows.push(externalProduct)
              $.each(externalProduct.internalSkus, function (index, internalSku) {
                if (internalSkus[internalSku] != null) {
                  rows.push(internalSkus[internalSku])
                }
              })
            }
          }
        }
        break
      }
      
      // Build the Table
      var productTable = $('<table/>')
      
      productTable.addClass('sol-table')
      
      // Build Table Header from defined fields
      var headerRow = $('<thead/>')
      headerRow.append($('<tr/>'))
      
      $.each(fields, function(key) {
        headerRow.append($('<th/>').text(this.label))
      })
      
      productTable.append(headerRow)
      productTable.append($('<tbody/>'))
      
      // Loop through result rows to build DOM rows
      $.each(rows, function() {
        var rowData = this
        var row = $('<tr/>')
        
        // Loop through all fields for this row
        $.each(fields, function(key) {
          // if this is a Solaris product and the sku field, wrap the value in a link to the product page
          if ((key == 'sku') && (rowData.company == 'Solaris')) {
            row.append($('<td/>').append($('<a/>').attr('href', '/' + rowData.url).text(cleanFormat(rowData[key], this.format))))
          } else {
            row.append($('<td/>').text(cleanFormat(rowData[key], this.format)))
          }
        })
        productTable.append(row)
      })
      
      $('.search-clean').html(match.val)
      
      // Show the results on the page
      $('.form-output').html(productTable)
      
      if (rows.length == 0) {
        $('.form-output').html('<div class="columns"><h3>0 Results</h3></div>')
      }
    }
    
    function toInt(value) {
      return parseInt(String(value).replace(/\D/g, ''))
    }
    
    function cleanFormat(value, format) {
      switch (format) {
        case 'int':
        return intFormat(value)
        
        case 'text':
        return value
      }
    }
    
    function intFormat(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    
    function isInt(value) {
      return !isNaN(value) && parseInt(Number(value)) == value && !isNaN(parseInt(value, 10))
    }
    
    function arrayUnique(array) {
      var a = array.concat();
      for (var i = 0; i < a.length; ++i) {
        for (var j = i + 1; j < a.length; ++j) {
          if (a[i] === a[j])
          a.splice(j--, 1)
        }
      }
      
      return a;
    }
    
    function sleep(milliseconds) {
      var start = new Date().getTime();
      for (var i = 0; i < 1e7; i++) {
        if ((new Date().getTime() - start) > milliseconds) {
          break
        }
      }
    }(function () {
      
      var FuzzySet = function (arr, useLevenshtein, gramSizeLower, gramSizeUpper) {
        var fuzzyset = {
          version: '0.0.1'
        };
        
        // default options
        arr = arr || [];
        fuzzyset.gramSizeLower = gramSizeLower || 2;
        fuzzyset.gramSizeUpper = gramSizeUpper || 3;
        fuzzyset.useLevenshtein = (typeof useLevenshtein !== 'boolean')
        ? true
        : useLevenshtein;
        
        // define all the object functions and attributes
        fuzzyset.exactSet = {};
        fuzzyset.matchDict = {};
        fuzzyset.items = {};
        
        // helper functions
        var levenshtein = function (str1, str2) {
          var current = [],
          prev,
          value;
          
          for (var i = 0; i <= str2.length; i++)
          for (var j = 0; j <= str1.length; j++) {
            if (i && j)
            if (str1.charAt(j - 1) === str2.charAt(i - 1))
            value = prev;
            else
            value = Math.min(current[j], current[j - 1], prev) + 1;
            else
            value = i + j;
            
            prev = current[j];
            current[j] = value;
          }
          
          return current.pop();
        };
        
        // return an edit distance from 0 to 1
        var _distance = function (str1, str2) {
          if (str1 === null && str2 === null)
          throw 'Trying to compare two null values';
          if (str1 === null || str2 === null)
          return 0;
          str1 = String(str1);
          str2 = String(str2);
          
          var distance = levenshtein(str1, str2);
          if (str1.length > str2.length) {
            return 1 - distance / str1.length;
          } else {
            return 1 - distance / str2.length;
          }
        };
        var _nonWordRe = /[^a-zA-Z0-9\u00C0-\u00FF, ]+/;
        
        var _iterateGrams = function (value, gramSize) {
          gramSize = gramSize || 2;
          var simplified = '-' + value.toLowerCase().replace(_nonWordRe, '') + '-',
          lenDiff = gramSize - simplified.length,
          results = [];
          if (lenDiff > 0) {
            for (var i = 0; i < lenDiff; ++i) {
              value += '-';
            }
          }
          for (var i = 0; i < simplified.length - gramSize + 1; ++i) {
            results.push(simplified.slice(i, i + gramSize));
          }
          return results;
        };
        
        var _gramCounter = function (value, gramSize) {
          // return an object where key=gram, value=number of occurrences
          gramSize = gramSize || 2;
          var result = {},
          grams = _iterateGrams(value, gramSize),
          i = 0;
          for (i; i < grams.length; ++i) {
            if (grams[i] in result) {
              result[grams[i]] += 1;
            } else {
              result[grams[i]] = 1;
            }
          }
          return result;
        };
        
        // the main functions
        fuzzyset.get = function (value, defaultValue) {
          // check for value in set, returning defaultValue or null if none found
          var result = this._get(value);
          if (!result && typeof defaultValue !== 'undefined') {
            return defaultValue;
          }
          return result;
        };
        
        fuzzyset._get = function (value) {
          var normalizedValue = this._normalizeStr(value),
          result = this.exactSet[normalizedValue];
          if (result) {
            return [
              [1, result]
            ];
          }
          
          var results = [];
          // start with high gram size and if there are no results, go to lower gram sizes
          for (var gramSize = this.gramSizeUpper; gramSize >= this.gramSizeLower; --gramSize) {
            results = this.__get(value, gramSize);
            if (results) {
              return results;
            }
          }
          return null;
        };
        
        fuzzyset.__get = function (value, gramSize) {
          var normalizedValue = this._normalizeStr(value),
          matches = {},
          gramCounts = _gramCounter(normalizedValue, gramSize),
          items = this.items[gramSize],
          sumOfSquareGramCounts = 0,
          gram,
          gramCount,
          i,
          index,
          otherGramCount;
          
          for (gram in gramCounts) {
            gramCount = gramCounts[gram];
            sumOfSquareGramCounts += Math.pow(gramCount, 2);
            if (gram in this.matchDict) {
              for (i = 0; i < this.matchDict[gram].length; ++i) {
                index = this.matchDict[gram][i][0];
                otherGramCount = this.matchDict[gram][i][1];
                if (index in matches) {
                  matches[index] += gramCount * otherGramCount;
                } else {
                  matches[index] = gramCount * otherGramCount;
                }
              }
            }
          }
          
          function isEmptyObject(obj) {
            for (var prop in obj) {
              if (obj.hasOwnProperty(prop))
              return false;
            }
            return true;
          }
          
          if (isEmptyObject(matches)) {
            return null;
          }
          
          var vectorNormal = Math.sqrt(sumOfSquareGramCounts),
          results = [],
          matchScore;
          // build a results list of [score, str]
          for (var matchIndex in matches) {
            matchScore = matches[matchIndex];
            results.push([
              matchScore / (vectorNormal * items[matchIndex][0]),
              items[matchIndex][1]
            ]);
          }
          var sortDescending = function (a, b) {
            if (a[0] < b[0]) {
              return 1;
            } else if (a[0] > b[0]) {
              return -1;
            } else {
              return 0;
            }
          };
          results.sort(sortDescending);
          if (this.useLevenshtein) {
            var newResults = [],
            endIndex = Math.min(50, results.length);
            // truncate somewhat arbitrarily to 50
            for (var i = 0; i < endIndex; ++i) {
              newResults.push([
                _distance(results[i][1], normalizedValue),
                results[i][1]
              ]);
            }
            results = newResults;
            results.sort(sortDescending);
          }
          var newResults = [];
          for (var i = 0; i < results.length; ++i) {
            if (results[i][0] == results[0][0]) {
              newResults.push([
                results[i][0],
                this.exactSet[results[i][1]]
              ]);
            }
          }
          return newResults;
        };
        
        fuzzyset.add = function (value) {
          var normalizedValue = this._normalizeStr(value);
          if (normalizedValue in this.exactSet) {
            return false;
          }
          
          var i = this.gramSizeLower;
          for (i; i < this.gramSizeUpper + 1; ++i) {
            this._add(value, i);
          }
        };
        
        fuzzyset._add = function (value, gramSize) {
          var normalizedValue = this._normalizeStr(value),
          items = this.items[gramSize] || [],
          index = items.length;
          
          items.push(0);
          var gramCounts = _gramCounter(normalizedValue, gramSize),
          sumOfSquareGramCounts = 0,
          gram,
          gramCount;
          for (gram in gramCounts) {
            gramCount = gramCounts[gram];
            sumOfSquareGramCounts += Math.pow(gramCount, 2);
            if (gram in this.matchDict) {
              this.matchDict[gram].push([index, gramCount]);
            } else {
              this.matchDict[gram] = [
                [index, gramCount]
              ];
            }
          }
          var vectorNormal = Math.sqrt(sumOfSquareGramCounts);
          items[index] = [vectorNormal, normalizedValue];
          this.items[gramSize] = items;
          this.exactSet[normalizedValue] = value;
        };
        
        fuzzyset._normalizeStr = function (str) {
          if (Object.prototype.toString.call(str) !== '[object String]')
          throw 'Must use a string as argument to FuzzySet functions';
          return str.toLowerCase();
        };
        
        // return length of items in set
        fuzzyset.length = function () {
          var count = 0,
          prop;
          for (prop in this.exactSet) {
            if (this.exactSet.hasOwnProperty(prop)) {
              count += 1;
            }
          }
          return count;
        };
        
        // return is set is empty
        fuzzyset.isEmpty = function () {
          for (var prop in this.exactSet) {
            if (this.exactSet.hasOwnProperty(prop)) {
              return false;
            }
          }
          return true;
        };
        
        // return list of values loaded into set
        fuzzyset.values = function () {
          var values = [],
          prop;
          for (prop in this.exactSet) {
            if (this.exactSet.hasOwnProperty(prop)) {
              values.push(this.exactSet[prop]);
            }
          }
          return values;
        };
        
        // initialization
        var i = fuzzyset.gramSizeLower;
        for (i; i < fuzzyset.gramSizeUpper + 1; ++i) {
          fuzzyset.items[i] = [];
        }
        // add all the items to the set
        for (i = 0; i < arr.length; ++i) {
          fuzzyset.add(arr[i]);
        }
        
        return fuzzyset;
      };
      
      var root = this;
      // Export the fuzzyset object for **CommonJS**, with backwards-compatibility for the old `require()` API. If we're not in CommonJS, add `_` to the global object.
      if (typeof module !== 'undefined' && module.exports) {
        module.exports = FuzzySet;
        root.FuzzySet = FuzzySet;
      } else {
        root.FuzzySet = FuzzySet;
      }
      
    })()
    
    
  }
})