// Imports
import anime from 'animejs'
import { setTimeout } from 'timers';

// Variables
let sliderWrap = $('.products-slider-wrap')
let productSlider = $('.products-slider')
let sliderId = productSlider.parents('.products-slider-wrap').attr('id')
let product = $('.product-item')
let productTab = $('.showSlide')
let closeButton = $('.close-button')


// --------------------------------
// On Document Load
// --------------------------------
jQuery(document).ready(function($){

  // Initiate Slider
  productSlider.slick({
    arrows: false,
    adaptiveHeight: true
  })

  productTab.on('click', function(e){
    
    let thisProduct = $(this).parents('.product-item')
    let tabNumber = $(this).data('tab') - 1
    let targetSliderId = $(this).attr('href')
    let targetSliderWrap = $(targetSliderId)
    let targetSlider = $(targetSliderId).children('.products-slider')

    // Get desired slider height
    // let sliderHeight = targetSlider.children('')
    
    // Change Styling on Product Item
    product.removeClass('active')
    thisProduct.addClass('active')

    // Close any open sliders
    if(sliderWrap.attr('id') != targetSliderId) {
      sliderWrap.removeClass('open')
    }

    // Loop through all sliders
    $('.products-slider-wrap').each(function() {
      let aSlider = $(this).attr('id')
      let tSlider = targetSliderId.replace('#', '')

      // Check if slider matches target
      if(aSlider != tSlider) {
        $('#' + aSlider).removeClass('open')
        
        // Animate Slider Closed
        setTimeout(function() {
          let hideSlider = anime({
            targets: '#' + aSlider,
            maxHeight: '0px',
            duration: 500,
            easing: 'easeInOutQuart'
          })
        }, 1500)

      } else if(aSlider == tSlider) {
        $('#' + aSlider).addClass('open')
        let height = $('#' + aSlider + ' .slick-list').height()
        height = height + (48*2)

        // Animate Slider Open
        let showSlider = anime({
          targets: '#' + aSlider,
          maxHeight: height,
          delay: 500,
          duration: 500,
          easing: 'easeInOutQuart'
        })
      }
    })

    // Go to targeted slide
    targetSlider.slick('slickGoTo', tabNumber)
  })

  // Close Current Slider
  closeButton.click(function() {
    let targetSliderId = $(this).data('slider')
    let targetSlider = $('#' + targetSliderId)
    // console.log(targetSliderId)

    let targetSection = $(this).parents('.subcategory')

    setTimeout(function() {

      $('html, body').animate({
        scrollTop: targetSection.offset().top - $('header').height()
      }, 1000 )

    }, 500)
    
    targetSlider.removeClass('open')

    // Animate Slider Closed
    let hideSlider = anime({
      targets: '#' + targetSliderId,
      maxHeight: '0px',
      duration: 500,
      easing: 'easeInOutQuart'
    })

    // targetSlider.slideUp()
    product.removeClass('active')
  })

  
  // Change Tab Class on Slide change
  productSlider.on('swipe', function(event, slick, direction) {
    let thisSubcat = $(this).parents('.subcategory').attr('id')
    let slideNumber = slick.currentSlide + 1
    let currentTabLink = $('#' + thisSubcat + ' .product-item a[data-tab=' + slideNumber + ']')
    let currentTab = currentTabLink.parents('.product-item')
    
    // console.log(currentTab)
    
    product.removeClass('active')
    currentTab.addClass('active')
  })
  
  
  
  // --------------------------------
  // When Internally linked to
  // --------------------------------
  document.addEventListener('swup:contentReplaced', function() {
      
      // Variables
      let sliderWrap = $('.products-slider-wrap')
      let productSlider = $('.products-slider')
      let sliderId = productSlider.parents('.products-slider-wrap').attr('id')
      let product = $('.product-item')
      let productTab = $('.showSlide')
      let closeButton = $('.close-button')
      
      // Initiate Slider
      productSlider.slick({
        arrows: false,
        adaptiveHeight: true
      })
      
      productTab.on('click', function(e){
        
        e.preventDefault()
        
        let thisProduct = $(this).parents('.product-item')
        let tabNumber = $(this).data('tab') - 1
        let targetSliderId = $(this).attr('href')
        let targetSliderWrap = $(targetSliderId)
        let targetSlider = $(targetSliderId).children('.products-slider')
        
        // Get desired slider height
        // let sliderHeight = targetSlider.children('')
        
        // Change Styling on Product Item
        product.removeClass('active')
        thisProduct.addClass('active')
        
        // Close any open sliders
        if(sliderWrap.attr('id') != targetSliderId) {
          sliderWrap.removeClass('open')
        }
        
        // Loop through all sliders
        $('.products-slider-wrap').each(function() {
          let aSlider = $(this).attr('id')
          let tSlider = targetSliderId.replace('#', '')
          
          // Check if slider matches target
          if(aSlider != tSlider) {
            $('#' + aSlider).removeClass('open')
            
            // Animate Slider Closed
            let hideSlider = anime({
              targets: '#' + aSlider,
              maxHeight: '0px',
              duration: 500,
              easing: 'easeInOutQuart'
            })
          } else if(aSlider == tSlider) {
            $('#' + aSlider).addClass('open')
            let height = $('#' + aSlider + ' .slick-list').height()
            height = height + (48*2)
            
            // Animate Slider Open
            let showSlider = anime({
              targets: '#' + aSlider,
              maxHeight: height,
              delay: 500,
              duration: 500,
              easing: 'easeInOutQuart'
            })
          }
        })
        
        // Go to targeted slide
        targetSlider.slick('slickGoTo', tabNumber)
      })
      
      // Close Current Slider
      closeButton.click(function() {
        let targetSliderId = $(this).data('slider')
        let targetSlider = $('#' + targetSliderId)
        // console.log(targetSliderId)
        
        let targetSection = $(this).parents('.subcategory')
        
        $('html, body').animate({
          scrollTop: targetSection.offset().top - $('header').height()
        }, 1000 )
        
        targetSlider.removeClass('open')
        
        // Animate Slider Closed
        let hideSlider = anime({
          targets: '#' + targetSliderId,
          maxHeight: '0px',
          duration: 500,
          easing: 'easeInOutQuart'
        })
        
        // targetSlider.slideUp()
        product.removeClass('active')
      })
      
      // Change Tab Class on Slide change
      productSlider.on('swipe', function(event, slick, direction) {
        let thisSubcat = $(this).parents('.subcategory').attr('id')
        let slideNumber = slick.currentSlide + 1
        let currentTabLink = $('#' + thisSubcat + ' .product-item a[data-tab=' + slideNumber + ']')
        let currentTab = currentTabLink.parents('.product-item')
        
        // console.log(currentTab)
        
        product.removeClass('active')
        currentTab.addClass('active')
      })
    
  })
})