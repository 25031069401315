jQuery(document).ready(function($) {

  $('.color-item').hover(function() {
    var color = this.dataset.originalTitle
    color = color.toLowerCase()
    // console.log(color)

    var thumbnail = $(this).parents('.product-item').children('.product-item-thumb').children('.showSlide').children('.square-thumb-wrap').children('.square-thumb')
    
    thumbnail.each(function() {
      if( $(this).hasClass(color) ) {
        $(this).addClass('show')
      } else {
        $(this).removeClass('show')
      }
    })
  
  })

  
  document.addEventListener('swup:contentReplaced', function() {
    
    $('.color-item').hover(function() {
      var color = this.dataset.originalTitle
      color = color.toLowerCase()
      // console.log(color)
      
      var thumbnail = $(this).parents('.product-item').children('.product-item-thumb').children('.showSlide').children('.square-thumb-wrap').children('.square-thumb')
      
      thumbnail.each(function() {
        if( $(this).hasClass(color) ) {
          $(this).addClass('show')
        } else {
          $(this).removeClass('show')
        }
      })
      
    })
    
  })
})