import { setTimeout } from "timers";

// Animate grid blocks in

jQuery(document).ready(function($) {
  let grid = $('.block-grid')
  let blockItems = $('.block-item')
  let delay = 200

  let triggerPoint = $(window).scrollTop() + 3*($(window).height() / 4)
  
  // Check if wrap has class 'animate'
  if(grid.hasClass('animate')) {

    // Loop through all block items
    blockItems.each(function() {
      let block = $(this)
      let blockPosition = block.offset().top
      let gridEnd = grid.offset().top + grid.height()
      let blockIndex = blockItems.index(this) + 1
      
      if(triggerPoint >= blockPosition && $(window).scrollTop() <= gridEnd) {

        
        if( block.hasClass('show') ) {
          delay = 100
        } else {
          setTimeout(function() {
            block.addClass('show')
          }, delay*blockIndex)
        }

      }

    })
  }
  
  window.addEventListener('scroll', function() {
    let triggerPoint = $(window).scrollTop() + 3*($(window).height() / 4)

    // Check if wrap has class 'animate'
    if(grid.hasClass('animate')) {

      // Loop through all block items
      blockItems.each(function() {
        let block = $(this)
        let blockPosition = block.offset().top
        let gridEnd = grid.offset().top + grid.height()
        let blockIndex = blockItems.index(this) + 1
        
        if(triggerPoint >= blockPosition && $(window).scrollTop() <= gridEnd) {

          
          if( block.hasClass('show') ) {
            delay = 100
          } else {
            setTimeout(function() {
              block.addClass('show')
            }, delay*blockIndex)
          }

        }

      })
    }
  })

  
  // Correct for page transition
  document.addEventListener('swup:contentReplaced', function() {
    
    let grid = $('.block-grid')
    let blockItems = $('.block-item')
    let delay = 200
    
    let triggerPoint = $(window).scrollTop() + 3*($(window).height() / 4)
    
    // Check if wrap has class 'animate'
    if(grid.hasClass('animate')) {
      
      // Loop through all block items
      blockItems.each(function() {
        let block = $(this)
        let blockPosition = block.offset().top
        let gridEnd = grid.offset().top + grid.height()
        let blockIndex = blockItems.index(this) + 1
        
        if(triggerPoint >= blockPosition && $(window).scrollTop() <= gridEnd) {
          
          delay = 200
          
          setTimeout(function() {
            block.addClass('show')
          }, delay*blockIndex)
          
        }
        
      })
    }
    
    window.addEventListener('scroll', function() {
      let triggerPoint = $(window).scrollTop() + 3*($(window).height() / 4)
      
      // Check if wrap has class 'animate'
      if(grid.hasClass('animate')) {
        
        // Loop through all block items
        blockItems.each(function() {
          let block = $(this)
          let blockPosition = block.offset().top
          let gridEnd = grid.offset().top + grid.height()
          let blockIndex = blockItems.index(this) + 1
          
          if(triggerPoint >= blockPosition && $(window).scrollTop() <= gridEnd) {
            
            delay = 200
            
            setTimeout(function() {
              block.addClass('show')
            }, delay*blockIndex)
            
          }
          
        })
      }
    })
  })
})