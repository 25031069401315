window.$ = window.jQuery = require('jquery')

require('bootstrap');
require('popper.js');
require('slick-carousel/slick/slick');
require('jquery-lazy')

require('./pageAnimation.js')
require('./lazy.js')
require('./slickconfig.js')
require('./stickyHeader.js')
require('./tabs.js')
require('./productSlider.js')
require('./main.js')
require('./scrollTo.js')
require('./mobileMenu.js')
require('./videoSlider.js')
require('./animateBlocks.js')
require('./animateTree.js')
require('./searchBar.js')
require('./sketchfabConfig.js')
require('./productFinder.js')
require('./productFinderReload.js')
require('./repSelect.js')
require('./productThumb.js')
require('./scrollTop.js')