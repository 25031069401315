jQuery(document).ready(function($){
  
  var iframe = document.getElementById( 'api-frame' );
  
  if(iframe) {
    // Add Variable ID Here
    var urlid = iframe.dataset.model;

    // By default, the latest version of the viewer API will be used.
    var client = new Sketchfab( iframe );

    client.init( urlid, {
      success: function onSuccess( api ){
        api.load();
        api.start();

        api.addEventListener( 'viewerready', function(){

          // API is ready to use
          // Insert your code here
          console.log( 'Viewer is ready' );
          // stop any animation
          api.pause()

          // get possible animations
          api.getAnimations( function(err, animations) {

            console.log(animations)
            for (animation of animations) {
              // create button
              var button = $('<button aria-label="'+ animation[1] + '" name="animation" data-id="' + animation[0] + '">' + animation[1] +'</button>')
              
              $(button).on('click', animation, function(el) {
                var id = $(this).data('id')
                console.log(id)
                api.pause()
                api.setCurrentAnimationByUID(id)
                api.play()
              })

              button.addClass('btn btn-primary btn-block')

              // Add button to DOM
              $('.model-button-group').prepend(button)

            }

            $('.loading-buttons').hide()
          })
          
        })
      },
      error: function onError() {
        console.log( 'Viewer error' )
      },
      ui_animations: 0,
      ui_annotations: 0,
      ui_controls: 0,
      ui_fullscreen: 0,
      ui_general_controls: 0,
      ui_help: 0,
      ui_hint: 0,
      ui_infos: 0,
      ui_inspector: 0,
      ui_inspector_open: 0,
      ui_settings: 0,
      ui_vr: 0
    });
  }

  
  document.addEventListener('swup:contentReplaced', function() {
    
    var iframe = document.getElementById( 'api-frame' );
    
    if(iframe) {
      // Add Variable ID Here
      var urlid = iframe.dataset.model;
      
      // By default, the latest version of the viewer API will be used.
      var client = new Sketchfab( iframe );
      
      client.init( urlid, {
        success: function onSuccess( api ){
          api.load();
          api.start();
          
          api.addEventListener( 'viewerready', function(){
            
            // API is ready to use
            // Insert your code here
            console.log( 'Viewer is ready' );
            // stop any animation
            api.pause()
            
            // get possible animations
            api.getAnimations( function(err, animations) {
              
              console.log(animations)
              for (animation of animations) {
                // create button
                var button = $('<button aria-label="'+ animation[1] + '" name="animation" data-id="' + animation[0] +'">' + animation[1] +'</button>')
                
                $(button).on('click', animation, function(el) {
                  var id = $(this).data('id')
                  console.log(id)
                  api.pause()
                  api.setCurrentAnimationByUID(id)
                  api.play()
                })
                
                button.addClass('btn btn-primary btn-block')
                
                // Add button to DOM
                $('.model-button-group').prepend(button)
                
              }
              
              $('.loading-buttons').hide()
            })
            
          })
        },
        error: function onError() {
          console.log( 'Viewer error' )
        },
        ui_animations: 0,
        ui_annotations: 0,
        ui_controls: 0,
        ui_fullscreen: 0,
        ui_general_controls: 0,
        ui_help: 0,
        ui_hint: 0,
        ui_infos: 0,
        ui_inspector: 0,
        ui_inspector_open: 0,
        ui_settings: 0,
        ui_vr: 0
      });
    }
    
  });
});