// On Page Load
jQuery(document).ready(function($) {
  
  $('.lazy').Lazy({
    effect: 'fadeIn',
    afterLoad: function(element) {
      element.addClass('clear')
    },
    onError: function(element) {
      console.log('error loading ' + element.data('src'))
    }
  })
  
  // On Slide Change
  $('.hero-slider').on('afterChange', function(event) {
    let current = $(event.currentTarget)
    let images = current.find('.lazy')
    images.Lazy({
      effect: 'fadeIn',
      afterLoad: function(element) {
        element.addClass('clear')
      },
      onError: function(element) {
        console.log('error loading ' + element.data('src'))
      }
    })
  })
  
  $('.products-slider').on('afterChange', function(event) {
    let current = $(event.currentTarget)
    let images = current.find('.lazy')
    images.Lazy({
      effect: 'fadeIn',
      afterLoad: function(element) {
        element.addClass('clear')
      },
      onError: function(element) {
        console.log('error loading ' + element.data('src'))
      }
    })
  })
  
  // On content load
  document.addEventListener('swup:contentReplaced', function() {
    $('.lazy').Lazy({
      effect: 'fadeIn',
      afterLoad: function(element) {
        element.addClass('clear')
      },
      onError: function(element) {
        console.log('error loading ' + element.data('src'))
      }
    })
    
    $('.hero-slider').on('afterChange', function(event) {
      let current = $(event.currentTarget)
      let images = current.find('.lazy')
      images.Lazy({
        effect: 'fadeIn',
        afterLoad: function(element) {
          element.addClass('clear')
        },
        onError: function(element) {
          console.log('error loading ' + element.data('src'))
        }
      })
    })
    
    $('.products-slider').on('afterChange', function(event) {
      let current = $(event.currentTarget)
      let images = current.find('.lazy')
      images.Lazy({
        effect: 'fadeIn',
        afterLoad: function(element) {
          element.addClass('clear')
        },
        onError: function(element) {
          console.log('error loading ' + element.data('src'))
        }
      })
    })
  })
})